export const getStoreAdminSensors = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      first
      last
      email
      phone
      code
      approved
      store {
        id
        name
        locations {
          items {
            id
            name
            sensors {
              items {
                id
                uuid
                device {
                  id
                  model
                }
                location {
                  id
                  name
                }
                label
                name
                duel
                duelId
                serialNumber
                oem
                harnessNumber
                partNumber
                development
                downlink
                test
                version
                online
                currentTemp
                currentTemp2
                currentTempFull
                certified
                ambient
                displayValues
                code
                recording
                locationNote
                sensorLocationId
                sensorDeviceId
                sensorUserId
                active
                alert
                alarm
                high
                low
                lowC
                highC
                email
                phone
                time
                raw
                duration
                monitor
                battery
                humidity
                leak
                notes
                lastCheckIn
                reportingEnabled
                validated
                createdAt
                updatedAt
                __typename
              }
            }
          }
          nextToken
          __typename

        }
        createdAt
        updatedAt
        __typename
      }
    }
  }
`;
export const getLocationAdminSensors = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      first
      last
      email
      phone
      code
      approved
      location {
        id
        name
        sensors {
          items {
            id
            uuid
            device {
              id
              model
            }
            location {
              id
              name
            }
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            active
            alert
            alarm
            high
            low
            lowC
            highC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            validated
            createdAt
            updatedAt
            __typename
          }
        }
      }
    }
  }
`;
export const getAllLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        owner {
          id
          email
        }
        sensors {
          items {
            id
            uuid
            name
            high
            low
            lowC
            highC
            duel
            duelId
            online
            currentTemp
            currentTempFull
            sensorUserId
            certified
            lastCheckIn
          }
        }
      }
    }
  }
`;
export const listAllSensors = `query ListDistributors(
  $filter: ModelDistributorFilterInput
  $limit: Int
  $nextToken: String
) {
  listDistributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      stores(limit: 1000) {
        items {
          id
          name
          locations(limit: 1000) {
            items {
              id
              name
              address
              city
              state
              zip
              sensors(limit: 1000) {
                items {
                  id
                  uuid
                  label
                  online
                  certified
                  name
                  test
                  high
                  low
                  lowC
                  highC
                  duration
                  serialNumber
                  oem
                  harnessNumber
                  partNumber
                  development
                  device {
                    id
                    model
                  }
                  readings(limit: 1, sortDirection: DESC) {
                    items {
                      id
                      createdAt
                      temp1
                      temp2
                      temp3
                      temp4
                      voltage1
                      voltage2
                      voltage3
                      pressure1
                      pressure2
                      pressure3
                      min
                      minFull
                      am
                      b1Syrup
                      b2Syrup
                      b3Syrup
                      b4Syrup
                      b1SyrupOut
                      b2SyrupOut
                      b3SyrupOut
                      b4SyrupOut
                      b1Water
                      b2Water
                      b3Water
                      b4Water
                      b1Defrost
                      b2Defrost
                      b3Defrost
                      b4Defrost
                      b1Liquid
                      b2Liquid
                      b3Liquid
                      b4Liquid
                      b1Fill
                      b2Fill
                      b3Fill
                      b4Fill
                      b1BIB
                      b2BIB
                      b3BIB
                      b4BIB
                      b1C02
                      b2C02
                      b3C02
                      b4C02
                      b1Temp
                      b2Temp
                      b3Temp
                      b4Temp
                      ambient
                      H20Pressure
                      C02Pressure
                      leftHighPressureCoil
                      rightHighPressureCoil
                      compSuctionTemp
                      compReturnTemp
                      compCut
                      leftCompContCoil
                      rightCompContCoil
                      testSignal
                      testAcknowledge
                      gnd1
                      gnd2
                      gnd3
                      gnd4
                      ACReturn
                      ACIn
                      readingSensorId
                    }
                  }
                  tags(limit: 1000) {
                    items {
                      id
                    }
                  }
                  notes
                }
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const listSuperSensors = `query ListSensors(
  $filter: ModelSensorFilterInput
  $limit: Int
  $nextToken: String
) {
  listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      online
      battery
      humidity
      name
      low
      high
      lowC
      highC
      alarm
      alert
      location {
        id
        name
      }
      locationNote
      test
      duel
      duelId
      duration
      lastCheckIn
      currentTemp
      currentTemp2
      currentTempFull
      sensorUserId
      certified
      active
      uuid
      validated
      leak
      sensorDeviceId
      device {
        id
        model
      }
      user {
        id
        email
      }
    }
    nextToken
  }
}`

export const listTestingSensors = `query ListSensors(
  $filter: ModelSensorFilterInput
  $limit: Int
  $nextToken: String
) {
  listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      online
      battery
      humidity
      name
      low
      high
      lowC
      highC
      location {
        id
        name
      }
      locationNote
      test
      duel
      duelId
      duration
      lastCheckIn
      currentTemp
      currentTemp2
      currentTempFull
      sensorUserId
      certified
      active
      uuid
      validated
      leak
      device {
        model
      }
    }
    nextToken
  }
}`




export const listNotificationsBySensor = `query SensorByUUID(
  $uuid: String
  $sortDirection: ModelSortDirection
  $filter: ModelSensorFilterInput
  $limit: Int
  $nextToken: String
) {
  sensorByUUID(
    uuid: $uuid
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      duel
      duelId
      notifications {
        items {
          id
          type
          info
          nickname
        }
      }
    }
  }
}`

export const testingSensors = `query ListSensors(
  $filter: ModelSensorFilterInput
  $limit: Int
  $nextToken: String
) {
  listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      readings(limit: 200, sortDirection: DESC) {
        items {
          createdAt
          min
        }
      }
      uuid
    }
  }
}`

export const listAlarmsBySensor = `query SensorByUUID(
  $uuid: String
  $sortDirection: ModelSortDirection
  $filter: ModelSensorFilterInput
  $limit: Int
  $nextToken: String
) {
  sensorByUUID(
    uuid: $uuid
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      duel
      duelId
      alarms {
        items {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          events(limit: 1, sortDirection: DESC) {
            items {
              id
              resolved
              status
              result
            }
          }
        }
      }
    }
  }
}`

export const listAllUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      first
      last
      email
      auth
      degreePref
      status
      store {
        id
        name
      }
      distributor {
        id
        name
      }
      location {
        id
        name
      }
      approved
      sensors {
        items {
          id
          name
        }
      }
    }
  } 
}`

export const listUsersByDistributor = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      first
      last
      email
      approved
      degreePref
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            approved
            auth
          }
          nextToken
        }
        stores {
          items {
            id
            name
          }
          nextToken
        }
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
          }
          stores {
            nextToken
          }
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
          }
          nextToken
        }
        admins {
          items {
            id
            first
            last
            email
            approved
            auth
            degreePref
          }
          nextToken
        }
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        sensors {
          items {
            id
            uuid
            label
            name
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            sensorLocationId
            active
            notes
          }
          nextToken
        }
        users {
          items {
            id
            first
            last
            email
            approved
            auth
          }
          nextToken
        }
        store {
          id
          name
          distributor {
            id
            name
          }
          locations {
            nextToken
          }
          admins {
            nextToken
          }
        }
      }
      subs {
        items {
          id
          user {
            id
            first
            last
            email
            approved
            auth
          }
          sub {
            id
            topic
            deviceId
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

// export const listUsersByDistributor = `query ListDistributors(
//   $filter: ModelDistributorFilterInput
//   $limit: Int
//   $nextToken: String
// ) {
//   listDistributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
//     items {
//       id
//       name
//       owners {
//         items {
//           id
//           first
//           last
//           email
//           auth
//           approved
//         }
//       }
//       stores(limit: 1000) {
//         items {
//           id
//           name
//           locations(limit: 1000) {
//             items {
//               id
//               name
//               address
//               city
//               state
//               zip
//               users {
//                 items {
//                   id
//                   first
//                   last
//                   email
//                   auth
//                   approved
//                 }
//               }
//             }
//             nextToken
//           }
//           admins {
//             items {
//               id
//               first
//               last
//               email
//               auth
//               approved
//             }
//           }
//         }
//         nextToken
//       }
//     }
//     nextToken
//   }
// }
// `;



export const listStoreSensors = `query ListStores(
  $filter: ModelStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      locations(limit: 1000) {
        items {
          id
          name
          address
          city
          state
          zip
          sensors(limit: 1000, filter: { active: { eq: true}}) {
            items {
              id
              uuid
              label
              online
              certified
              name
              serialNumber
              oem
              harnessNumber
              partNumber
              development
              device {
                id
                model
              }
              readings(limit: 1, sortDirection: DESC) {
                items {
                  id
                  createdAt
                  temp1
                  temp2
                  temp3
                  temp4
                  voltage1
                  voltage2
                  voltage3
                  pressure1
                  pressure2
                  pressure3
                  min
                  minFull
                  am
                  b1Syrup
                  b2Syrup
                  b3Syrup
                  b4Syrup
                  b1SyrupOut
                  b2SyrupOut
                  b3SyrupOut
                  b4SyrupOut
                  b1Water
                  b2Water
                  b3Water
                  b4Water
                  b1Defrost
                  b2Defrost
                  b3Defrost
                  b4Defrost
                  b1Liquid
                  b2Liquid
                  b3Liquid
                  b4Liquid
                  b1Fill
                  b2Fill
                  b3Fill
                  b4Fill
                  b1BIB
                  b2BIB
                  b3BIB
                  b4BIB
                  b1C02
                  b2C02
                  b3C02
                  b4C02
                  b1Temp
                  b2Temp
                  b3Temp
                  b4Temp
                  ambient
                  H20Pressure
                  C02Pressure
                  leftHighPressureCoil
                  rightHighPressureCoil
                  compSuctionTemp
                  compReturnTemp
                  compCut
                  leftCompContCoil
                  rightCompContCoil
                  testSignal
                  testAcknowledge
                  gnd1
                  gnd2
                  gnd3
                  gnd4
                  ACReturn
                  ACIn
                  readingSensorId
                }
              }
              tags(limit: 1000) {
                items {
                  id
                }
              }
              notes
            }
          }
        }
        nextToken
      }
      admins {
        items {
          id
          first
          last
          email
          approved
          store {
            id
            name
          }
          auth
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const createSensor = /* GraphQL */ `
  subscription OnCreateSensor {
    onCreateSensor {
      id
      online
      battery
      humidity
      name
      low
      high
      lowC
      highC
      test
      duration
      lastCheckIn
      currentTemp
      currentTempFull
      sensorUserId
      certified
      active
      uuid
      leak
      location {
        id
        name
      }
    }
  }`

export const updateSensorSimple = `mutation UpdateSensor($input: UpdateSensorInput!) {
  updateSensor(input: $input) {
    id
    updatedAt
    online
    name
    low
    high
    lowC
    highC
    test
    duration
    duel
    duelId
    lastCheckIn
    currentTemp
    currentTemp2
    currentTempFull
    sensorUserId
    certified
    timezone
    ambient
    active
    recording
    uuid
    leak
    device {
        model
    }
    location {
        id
        name
    }
    user {
        id
        email
    }
    alarms {
        items {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          alarmSensorId
          createdAt
          updatedAt
          events(limit: 1, sortDirection: DESC) {
            items {
              id
              resolved
              status
              result
              notify
            }
          }
        }
    }
    battery
    humidity
    alert
    alarm
  }
}
`

export const updateSensor = /* GraphQL */ `
  subscription OnUpdateSensor {
    onUpdateSensor {
      id
      online
      battery
      humidity
      name
      low
      high
      lowC
      highC
      test
      duration
      lastCheckIn
      currentTemp
      currentTempFull
      sensorUserId
      certified
      timezone
      recording
      active
      uuid
      alert
      alarm
      leak
      location {
        id
        name
      }
      user {
        id
        email
      }
      device {
        id
        model
      }
      sensorDeviceId
      alarms {
        items {
          id
          type
          active
          events(sortDirection: DESC, limit: 1) {
            items {
              id
              status
              notify
            }
          }
        }
      }
    }
  }`

// export const listDistributorOwners = `query ListDistributors(
//   $filter: ModelDistributorFilterInput
//   $limit: Int
//   $nextToken: String
// ) {
//   listDistributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
//     items {
//       id
//       name
//       sensors(limit: 1000) {
//         items {
//           id
//           uuid
//           label
//           name
//           serial
//           oem
//           owner {
//             id
//             name
//           }
//           location {
//             id
//             name
//             address
//             city
//             state
//             zip
//           }
//           sensorLocationId
//           device {
//             id
//             model
//             manufacturer
//           }
//           distributor {
//             id
//             name
//           }
//           readings {
//             nextToken
//           }
//           tags {
//             nextToken
//           }
//           active
//           subs {
//             nextToken
//           }
//           notes
//         }
//         nextToken
//       }
//       owners(limit: 1000) {
//         items {
//           id
//           distributor {
//             id
//             name
//           }
//           owner {
//             id
//             name
//             sensors {
//               items {
//                 id
//                 label
//                 distributor {
//                   id
//                   name
//                 }
//               }
//             }
//           }
//         }
//         nextToken
//       }
//       users {
//         items {
//           id
//           distributor {
//             id
//             name
//           }
//           user {
//             id
//             first
//             last
//             email
//             approved
//             canAddSensor
//             auth
//           }
//         }
//         nextToken
//       }
//     }
//     nextToken
//   }
// }
// `;

export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      model
      sensors {
        items {
          id
          online
          battery
          humidity
          name
          low
          high
          lowC
          highC
          test
          duration
          lastCheckIn
          currentTemp
          currentTemp2
          currentTempFull
          sensorUserId
          certified
          t1
          t2
          t3
          t4
          p1
          p2
          p3
          p4
          p5
          p6
          v1
          v2
          v3
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          active
          uuid
          leak
          device {
            id
            model
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const getUserDevices = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    first
    last
    email
    phone
    code
    approved
    auth
    type
    degreePref
    resolution
    sensors(limit: 1000, filter: { active: { eq: true}}) {
      items {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        battery
        humidity
        currentTemp
        currentTemp2
        currentTempFull
        sensorUserId
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        recording
        sensorLocationId
        readings(limit: 1, sortDirection: DESC) {
          items {
            id
            createdAt
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            min
            minFull
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingSensorId
          }
        }
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        active
        alert
        high
        low
        lowC
        highC
        email
        phone
        time
        raw
        duration
        monitor
        notes
        leak
        lastCheckIn
      }
      nextToken
    }
  }
}`;


export const listUserSensors = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    first
    last
    email
    approved
    store {
      id
      name
      distributor {
        id
        name
        stores {
          items {
            id
            name
          }
          nextToken
        }
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          sensors(limit: 1000, filter: { active: { eq: true}}) {
            items {
              id
              uuid
              label
              name
              online
              certified
              battery
              humidity
              serialNumber
              oem
              harnessNumber
              partNumber
              development
              device {
                id
                model
              }
              readings(limit: 1, sortDirection: DESC) {
                items {
                  id
                  createdAt
                  temp1
                  temp2
                  temp3
                  temp4
                  voltage1
                  voltage2
                  voltage3
                  pressure1
                  pressure2
                  pressure3
                  min
                  minFull
                  am
                  b1Syrup
                  b2Syrup
                  b3Syrup
                  b4Syrup
                  b1SyrupOut
                  b2SyrupOut
                  b3SyrupOut
                  b4SyrupOut
                  b1Water
                  b2Water
                  b3Water
                  b4Water
                  b1Defrost
                  b2Defrost
                  b3Defrost
                  b4Defrost
                  b1Liquid
                  b2Liquid
                  b3Liquid
                  b4Liquid
                  b1Fill
                  b2Fill
                  b3Fill
                  b4Fill
                  b1BIB
                  b2BIB
                  b3BIB
                  b4BIB
                  b1C02
                  b2C02
                  b3C02
                  b4C02
                  b1Temp
                  b2Temp
                  b3Temp
                  b4Temp
                  ambient
                  H20Pressure
                  C02Pressure
                  leftHighPressureCoil
                  rightHighPressureCoil
                  compSuctionTemp
                  compReturnTemp
                  compCut
                  leftCompContCoil
                  rightCompContCoil
                  testSignal
                  testAcknowledge
                  gnd1
                  gnd2
                  gnd3
                  gnd4
                  ACReturn
                  ACIn
                  readingSensorId
                }
              }
              tags(limit: 1000) {
                items {
                  id
                }
              }
              notes
            }
          }
        }
        nextToken
      }
    }
    auth
    location {
      id
      name
      address
      city
      state
      zip
      sensors(limit: 1000, filter: { active: { eq: true}}) {
        items {
          id
          uuid
          label
          name
          online
          certified
          battery
          humidity
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          device {
            id
            model
          }
          readings(limit: 1, sortDirection: DESC) {
            items {
              id
              createdAt
              temp1
              temp2
              temp3
              temp4
              voltage1
              voltage2
              voltage3
              pressure1
              pressure2
              pressure3
              min
              minFull
              am
              b1Syrup
              b2Syrup
              b3Syrup
              b4Syrup
              b1SyrupOut
              b2SyrupOut
              b3SyrupOut
              b4SyrupOut
              b1Water
              b2Water
              b3Water
              b4Water
              b1Defrost
              b2Defrost
              b3Defrost
              b4Defrost
              b1Liquid
              b2Liquid
              b3Liquid
              b4Liquid
              b1Fill
              b2Fill
              b3Fill
              b4Fill
              b1BIB
              b2BIB
              b3BIB
              b4BIB
              b1C02
              b2C02
              b3C02
              b4C02
              b1Temp
              b2Temp
              b3Temp
              b4Temp
              ambient
              H20Pressure
              C02Pressure
              leftHighPressureCoil
              rightHighPressureCoil
              compSuctionTemp
              compReturnTemp
              compCut
              leftCompContCoil
              rightCompContCoil
              testSignal
              testAcknowledge
              gnd1
              gnd2
              gnd3
              gnd4
              ACReturn
              ACIn
              readingSensorId
            }
          }
          tags(limit: 1000) {
            items {
              id
            }
          }
          notes
        }
      }
    }
    subs {
      items {
        id
        user {
          id
          first
          last
          email
          approved
          store {
            id
            name
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
          }
          subs {
            nextToken
          }
        }
        sub {
          id
          topic
          deviceId
          users {
            nextToken
          }
          sensors {
            nextToken
          }
        }
      }
      nextToken
    }
  }
}
`;
export const getSingleTreatment = /* GraphQL */ `
  query GetTreatment($id: ID!) {
    getTreatment(id: $id) {
      id
      active
      start
      end
      fuelCell {
        name
        sensors {
          items {
            id
            name
            currentTemp
            online
          }
        }
      }
      readings(sortDirection: DESC, limit: 1000) {
        items {
          id
          createdAt
          min
          minFull
          sensor {
            name
          }
        }
      }
    }
  }
`;
export const getSingleFuelCell = /* GraphQL */ `
  query GetFuelCell($name: String!) {
    getFuelCell(name: $name) {
      name
      online
      fuel
      water
      lastUpdate
      treatments {
        items {
          id
          tag
          active
          start
          end
          createdAt
          readings(limit: 1000) {
            items {
              id
              min
              minFull
              createdAt
            }
          }
          murphyTemps(limit: 1000) {
            items {
              id
              temp
            }
          }
          fuelCell {
            name
            sensors {
              items {
                id
                recording
              }
            }
          }
        }
      }
      sensors {
        items {
          id
          name
          currentTemp
          online
          recording
          lastCheckIn
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerSensors = `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          currentTemp
          currentTempFull
          sensorUserId
          certified
          lastCheckIn
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          test
          high
          low
          lowC
          highC
          duration
          online
          battery
          humidity
          device {
            id
            model
          }
          readings(sortDirection: DESC, limit: 1) {
            items {
              id
              createdAt
              min
              minFull
              readingSensorId
            }
            nextToken
          }
        }
      }
    }
  }
`;
// export const ownerSensors = `query ListOwners(
//   $filter: ModelOwnerFilterInput
//   $limit: Int
//   $nextToken: String
// ) {
//   listOwners(filter: $filter, limit: $limit, nextToken: $nextToken) {
//     items {
//       id
//       name
//       owner {
//         id
//         first
//         last
//         email
//         approved
//         owns {
//           items {
//             id
//             name
//           }
//           nextToken
//         }
//         auth
//         admin {
//           id
//           name
//           owner {
//             id
//             first
//             last
//             email
//             approved
//             auth
//           }
//           sensors {
//             nextToken
//           }
//           admins {
//             nextToken
//           }
//           locations {
//             nextToken
//           }
//           distributors {
//             nextToken
//           }
//           users {
//             nextToken
//           }
//         }
//         distributors {
//           items {
//             id
//           }
//           nextToken
//         }
//         owner {
//           id
//           name
//           owner {
//             id
//             first
//             last
//             email
//             approved
//             auth
//           }
//           sensors {
//             nextToken
//           }
//           admins {
//             nextToken
//           }
//           locations {
//             nextToken
//           }
//           distributors {
//             nextToken
//           }
//           users {
//             nextToken
//           }
//         }
//         subs {
//           items {
//             id
//           }
//           nextToken
//         }
//         locations {
//           items {
//             id
//           }
//           nextToken
//         }
//       }
//       sensors {
//         items {
//           id
//           uuid
//           label
//           name
//           serial
//           oem
//           owner {
//             id
//             name
//           }
//           location {
//             id
//             name
//             address
//             city
//             state
//             zip
//           }
//           sensorLocationId
//           device {
//             id
//             model
//             manufacturer
//           }
//           distributor {
//             id
//             name
//           }
//           tags {
//             items {
//               id
//               tag {
//                 id
//                 name
//               }
//             }
//           }
//           readings(sortDirection: DESC, limit: 1) {
//             items {
//               id
//               createdAt
//               b1Syrup
//               b2Syrup
//               b3Syrup
//               b4Syrup
//               b1SyrupOut
//               b2SyrupOut
//               b3SyrupOut
//               b4SyrupOut
//               b1Water
//               b2Water
//               b3Water
//               b4Water
//               b1Defrost
//               b2Defrost
//               b3Defrost
//               b4Defrost
//               b1Liquid
//               b2Liquid
//               b3Liquid
//               b4Liquid
//               b1Fill
//               b2Fill
//               b3Fill
//               b4Fill
//               b1BIB
//               b2BIB
//               b3BIB
//               b4BIB
//               b1C02
//               b2C02
//               b3C02
//               b4C02
//               b1Temp
//               b2Temp
//               b3Temp
//               b4Temp
//               ambient
//               H20Pressure
//               C02Pressure
//               leftHighPressureCoil
//               rightHighPressureCoil
//               compSuctionTemp
//               compReturnTemp
//               compCut
//               leftCompContCoil
//               rightCompContCoil
//               testSignal
//               testAcknowledge
//               gnd1
//               gnd2
//               gnd3
//               gnd4
//               ACReturn
//               ACIn
//               readingSensorId
//             }
//             nextToken
//           }
//           tags {
//             nextToken
//           }
//           active
//           subs {
//             nextToken
//           }
//           notes
//         }
//         nextToken
//       }
//       admins {
//         items {
//           id
//           first
//           last
//           email
//           approved
//           owns {
//             nextToken
//           }
//           auth
//           admin {
//             id
//             name
//           }
//           distributors {
//             nextToken
//           }
//           owner {
//             id
//             name
//           }
//           subs {
//             nextToken
//           }
//           locations {
//             nextToken
//           }
//         }
//         nextToken
//       }
//       locations {
//         items {
//           id
//           name
//           address
//           city
//           state
//           zip
//           owner {
//             id
//             name
//           }
//           sensors {
//             nextToken
//           }
//           users {
//             nextToken
//           }
//         }
//         nextToken
//       }
//       distributors {
//         items {
//           id
//           distributor {
//             id
//             name
//           }
//           owner {
//             id
//             name
//           }
//         }
//         nextToken
//       }
//       users {
//         items {
//           id
//           first
//           last
//           email
//           approved
//           owns {
//             nextToken
//           }
//           auth
//           admin {
//             id
//             name
//           }
//           distributors {
//             nextToken
//           }
//           owner {
//             id
//             name
//           }
//           subs {
//             nextToken
//           }
//           locations {
//             nextToken
//           }
//         }
//         nextToken
//       }
//     }
//     nextToken
//   }
// }
// `;

// export const listUsersByOwner = `query ListOwners(
//   $filter: ModelOwnerFilterInput
//   $limit: Int
//   $nextToken: String
// ) {
//   listOwners(filter: $filter, limit: $limit, nextToken: $nextToken) {
//     items {
//       id
//       name
//       owner {
//         id
//         first
//         last
//         email
//         approved
//         owns {
//           items {
//             id
//             name
//           }
//           nextToken
//         }
//         auth
//         admin {
//           id
//           name
//           owner {
//             id
//             first
//             last
//             email
//             approved
//             auth
//           }
//           sensors {
//             nextToken
//           }
//           admins {
//             nextToken
//           }
//           locations {
//             nextToken
//           }
//           distributors {
//             nextToken
//           }
//           users {
//             nextToken
//           }
//         }
//         distributors {
//           items {
//             id
//           }
//           nextToken
//         }
//         owner {
//           id
//           name
//           owner {
//             id
//             first
//             last
//             email
//             approved
//             auth
//           }
//           sensors {
//             nextToken
//           }
//           admins {
//             nextToken
//           }
//           locations {
//             nextToken
//           }
//           distributors {
//             nextToken
//           }
//           users {
//             nextToken
//           }
//         }
//         subs {
//           items {
//             id
//           }
//           nextToken
//         }
//         locations {
//           items {
//             id
//           }
//           nextToken
//         }
//       }
//       sensors {
//         items {
//           id
//           uuid
//           label
//           name
//           serial
//           oem
//           owner {
//             id
//             name
//           }
//           location {
//             id
//             name
//             address
//             city
//             state
//             zip
//           }
//           sensorLocationId
//           device {
//             id
//             model
//             manufacturer
//           }
//           distributor {
//             id
//             name
//           }
//           tags {
//             items {
//               id
//               tag {
//                 id
//                 name
//               }
//             }
//           }
//           readings(sortDirection: DESC, limit: 1) {
//             items {
//               id
//               createdAt
//               b1Syrup
//               b2Syrup
//               b3Syrup
//               b4Syrup
//               b1SyrupOut
//               b2SyrupOut
//               b3SyrupOut
//               b4SyrupOut
//               b1Water
//               b2Water
//               b3Water
//               b4Water
//               b1Defrost
//               b2Defrost
//               b3Defrost
//               b4Defrost
//               b1Liquid
//               b2Liquid
//               b3Liquid
//               b4Liquid
//               b1Fill
//               b2Fill
//               b3Fill
//               b4Fill
//               b1BIB
//               b2BIB
//               b3BIB
//               b4BIB
//               b1C02
//               b2C02
//               b3C02
//               b4C02
//               b1Temp
//               b2Temp
//               b3Temp
//               b4Temp
//               ambient
//               H20Pressure
//               C02Pressure
//               leftHighPressureCoil
//               rightHighPressureCoil
//               compSuctionTemp
//               compReturnTemp
//               compCut
//               leftCompContCoil
//               rightCompContCoil
//               testSignal
//               testAcknowledge
//               gnd1
//               gnd2
//               gnd3
//               gnd4
//               ACReturn
//               ACIn
//               readingSensorId
//             }
//             nextToken
//           }
//           tags {
//             nextToken
//           }
//           active
//           subs {
//             nextToken
//           }
//           notes
//         }
//         nextToken
//       }
//       admins {
//         items {
//           id
//           first
//           last
//           email
//           approved
//           owns {
//             nextToken
//           }
//           auth
//           admin {
//             id
//             name
//           }
//           distributors {
//             nextToken
//           }
//           owner {
//             id
//             name
//           }
//           subs {
//             nextToken
//           }
//           locations {
//             nextToken
//           }
//         }
//         nextToken
//       }
//       locations {
//         items {
//           id
//           name
//           address
//           city
//           state
//           zip
//           owner {
//             id
//             name
//           }
//           sensors {
//             nextToken
//           }
//           users {
//             nextToken
//           }
//         }
//         nextToken
//       }
//       distributors {
//         items {
//           id
//           distributor {
//             id
//             name
//           }
//           owner {
//             id
//             name
//           }
//         }
//         nextToken
//       }
//       users {
//         items {
//           id
//           first
//           last
//           email
//           approved
//           owns {
//             nextToken
//           }
//           auth
//           admin {
//             id
//             name
//           }
//           distributors {
//             items {
//               distributor {
//                 name
//               }
//             }
//           }
//           owner {
//             id
//             name
//           }
//           subs {
//             nextToken
//           }
//           locations {
//             items {
//               location {
//                 id
//                 name
//               }
//             }
//             nextToken
//           }
//         }
//         nextToken
//       }
//     }
//     nextToken
//   }
// }
// `;

// export const listUsersByDistributor = `query ListDistributors(
//   $filter: ModelDistributorFilterInput
//   $limit: Int
//   $nextToken: String
// ) {
//   listDistributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
//     items {
//       id
//       name
//       users(limit: 1000) {
//         items {
//           id
//           distributor {
//             id
//             name
//           }
//           user {
//             id
//             first
//             last
//             email
//             approved
//             auth
//             distributors {
//               items {
//                 distributor {
//                   name
//                 }
//               }
//             }
//             locations {
//               items {
//                 location {
//                   name
//                 }
//               }
//             }
//           }
//         }
//         nextToken
//       }
//     }
//     nextToken
//   }
// }
// `;
// export const distributorSensors = `query ListDistributors(
//   $filter: ModelDistributorFilterInput
//   $limit: Int
//   $nextToken: String
// ) {
//   listDistributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
//     items {
//       id
//       name
//       sensors(limit: 1000) {
//         items {
//           id
//           uuid
//           label
//           name
//           serial
//           oem
//           sensorLocationId
//           active
//           notes
//           tags {
//             items {
//               id
//             }
//           }
//           owner {
//             id
//             name
//           }
//           distributor {
//             id
//             name
//           }
//           location {
//             id
//             name
//           }
//           readings(sortDirection: DESC, limit: 1) {
//             items {
//               id
//               createdAt
//               b1Syrup
//               b2Syrup
//               b3Syrup
//               b4Syrup
//               b1SyrupOut
//               b2SyrupOut
//               b3SyrupOut
//               b4SyrupOut
//               b1Water
//               b2Water
//               b3Water
//               b4Water
//               b1Defrost
//               b2Defrost
//               b3Defrost
//               b4Defrost
//               b1Liquid
//               b2Liquid
//               b3Liquid
//               b4Liquid
//               b1Fill
//               b2Fill
//               b3Fill
//               b4Fill
//               b1BIB
//               b2BIB
//               b3BIB
//               b4BIB
//               b1C02
//               b2C02
//               b3C02
//               b4C02
//               b1Temp
//               b2Temp
//               b3Temp
//               b4Temp
//               ambient
//               H20Pressure
//               C02Pressure
//               leftHighPressureCoil
//               rightHighPressureCoil
//               compSuctionTemp
//               compReturnTemp
//               compCut
//               leftCompContCoil
//               rightCompContCoil
//               testSignal
//               testAcknowledge
//               gnd1
//               gnd2
//               gnd3
//               gnd4
//               ACReturn
//               ACIn
//               readingSensorId
//             }
//             nextToken
//           }
//         }
//       }
//     }
//     nextToken
//   }
// }
// `;
export const dataReadings = /* GraphQL */ `
  query CheckReadings(
    $sensorId: String
    $number: Int
    $unit: Unit
    $temp: Int
    $operator: String
    $from: String
    $to: String
    $nextToken: String
  ) {
    checkReadings(
      sensorId: $sensorId
      number: $number
      unit: $unit
      temp: $temp
      operator: $operator
      from: $from
      to: $to
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        min
        minFull
        readingSensorId
        temp1
        temp2
        temp3
        temp4
        pressure1
        pressure2
        pressure3
        pressure4
        pressure5
        pressure6
        voltage1
        voltage2
        voltage3
      }
      nextToken
    }
  }
`;

export const listMDXSensorReadings = `query ListSensors(
  $filter: ModelSensorFilterInput
  $limit: Int
  $nextToken: String
) {
  listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      uuid
      label
      name
      online
      battery
      humidity
      serialNumber
      oem
      high
      low
      lowC
      highC
      duration
      time
      phone
      email
      displayValues
      readings(limit: 20, sortDirection: DESC) {
        items {
          id
          createdAt
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          readingSensorId
        }
        nextToken
      }
      active
    }
  }
}
`;
export const listSensorReadings = `query ListSensors(
  $filter: ModelSensorFilterInput
  $limit: Int
  $nextToken: String
) {
  listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      uuid
      label
      duel
      duelId
      name
      online
      certified
      alert
      leak
      alarm
      alarms {
        items {
          id
          type
          active
          events(sortDirection: DESC, limit: 1) {
            items {
              id
              status
              notify
            }
          }
        }
      }
      battery
      humidity
      serialNumber
      oem
      high
      low
      lowC
      highC
      duration
      reportingEnabled
      time
      phone
      email
      lastCheckIn
      displayValues
      location {
        id
        name
      }
      device {
        id
        model
        manufacturer
        sensors {
          items {
            id
            uuid
            label
            name
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            device {
              id
              model
            }
            sensorLocationId
            active
            notes
          }
          nextToken
        }
      }
      logs(limit: 50, sortDirection: DESC) {
        items {
          id
          createdAt
          alarm
          alerts
          trigger
        }
      }
      readings(limit: 20, sortDirection: DESC) {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            serialNumber
            oem
            sensorLocationId
            active
            notes
            high
            low
            lowC
            highC
            duration
            phone
            email
            time
          }
          createdAt
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          min
          min2
          minFull
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
        }
        nextToken
      }
      active
      notes
    }
    nextToken
  }
}
`;

export const listSingleSensorReadings = `query SensorByUUID(
  $uuid: String
  $sortDirection: ModelSortDirection
  $filter: ModelSensorFilterInput
  $limit: Int
  $nextToken: String
) {
  sensorByUUID(
    uuid: $uuid
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      uuid
      label
      duel
      duelId
      name
      online
      certified
      timezone
      alert
      leak
      sensorUserId
      sensorDeviceId
      alarm
      alarms {
        items {
          id
          type
          active
          events(sortDirection: DESC, limit: 1) {
            items {
              id
              status
              notify
            }
          }
        }
      }
      events(sortDirection: DESC, limit: 50) {
        items {
          id
          createdAt
          alarm {
            id
            type
            active
            value
            valueC
          }
          messages {
            items {
              id
              responseBy
              userResponse
              updatedAt
            }
          }
          resolved
          status
          result
          notify
          notes
          notifications {
            items {
              id
              notification {
                id
                type
                info
                active
                nickname
              }
            }
          }
        }
      }
      battery
      humidity
      serialNumber
      oem
      high
      low
      lowC
      highC
      duration
      reportingEnabled
      time
      phone
      email
      lastCheckIn
      displayValues
      location {
        id
        name
      }
      device {
        id
        model
        manufacturer
        sensors {
          items {
            id
            uuid
            label
            name
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            device {
              id
              model
            }
            sensorLocationId
            active
            notes
          }
          nextToken
        }
      }
      logs(limit: 50, sortDirection: DESC) {
        items {
          id
          createdAt
          alarm
          alerts
          trigger
        }
      }
      readings(limit: 20, sortDirection: DESC) {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            serialNumber
            oem
            sensorLocationId
            active
            notes
            high
            low
            lowC
            highC
            duration
            phone
            email
            time
          }
          createdAt
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          min
          min2
          minFull
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
        }
        nextToken
      }
      active
      notes
    }
    nextToken
  }
}
`;
// export const sortedListSensors = `query ListSensors(
//   $filter: ModelSensorFilterInput
//   $limit: Int
//   $nextToken: String
// ) {
//   listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
//     items {
//       id
//       uuid
//       label
//       name
//       serial
//       oem
//       location {
//         id
//         name
//         address
//         city
//         state
//         zip
//         sensors {
//           items {
//             id
//             uuid
//             label
//             name
//             serial
//             oem
//             sensorLocationId
//             active
//             notes
//           }
//           nextToken
//         }
//         users {
//           items {
//             id
//             first
//             last
//             email
//             approved
//             auth
//           }
//           nextToken
//         }
//         store {
//           id
//           name
//           distributor {
//             id
//             name
//           }
//           locations {
//             nextToken
//           }
//           admins {
//             nextToken
//           }
//         }
//       }
//       sensorLocationId
//       device {
//         id
//         model
//         manufacturer
//         sensors {
//           items {
//             id
//             uuid
//             label
//             name
//             serial
//             oem
//             sensorLocationId
//             active
//             notes
//           }
//           nextToken
//         }
//       }
//       readings(limit: 1, sortDirection: DESC) {
//         items {
//           id
//           sensor {
//             id
//             uuid
//             label
//             name
//             serial
//             oem
//             sensorLocationId
//             active
//             notes
//           }
//           createdAt
//           b1Syrup
//           b2Syrup
//           b3Syrup
//           b4Syrup
//           b1SyrupOut
//           b2SyrupOut
//           b3SyrupOut
//           b4SyrupOut
//           b1Water
//           b2Water
//           b3Water
//           b4Water
//           b1Defrost
//           b2Defrost
//           b3Defrost
//           b4Defrost
//           b1Liquid
//           b2Liquid
//           b3Liquid
//           b4Liquid
//           b1Fill
//           b2Fill
//           b3Fill
//           b4Fill
//           b1BIB
//           b2BIB
//           b3BIB
//           b4BIB
//           b1C02
//           b2C02
//           b3C02
//           b4C02
//           b1Temp
//           b2Temp
//           b3Temp
//           b4Temp
//           ambient
//           H20Pressure
//           C02Pressure
//           leftHighPressureCoil
//           rightHighPressureCoil
//           compSuctionTemp
//           compReturnTemp
//           compCut
//           leftCompContCoil
//           rightCompContCoil
//           testSignal
//           testAcknowledge
//           gnd1
//           gnd2
//           gnd3
//           gnd4
//           ACReturn
//           ACIn
//           readingSensorId
//         }
//         nextToken
//       }
//       tags {
//         items {
//           id
//           sensor {
//             id
//             uuid
//             label
//             name
//             serial
//             oem
//             sensorLocationId
//             active
//             notes
//           }
//           tag {
//             id
//             name
//           }
//         }
//         nextToken
//       }
//       active
//       subs {
//         items {
//           id
//           sensor {
//             id
//             uuid
//             label
//             name
//             serial
//             oem
//             sensorLocationId
//             active
//             notes
//           }
//           sub {
//             id
//             topic
//             deviceId
//           }
//         }
//         nextToken
//       }
//       notes
//     }
//     nextToken
//   }
// }
// `;