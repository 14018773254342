import router from '@/router'
import {
    Auth,
    API,
    graphqlOperation
} from 'aws-amplify';
import {
    listDistributors,
    listDistributorUsers,
    listUsers,
    listOwners,
    getUser,
    locationByCode,
    userByCode
} from '@/graphql/queries'
import {
    listUsersByDistributor,
    getUserDevices,
    listAllUsers,
    getStoreAdminSensors
    // listUsersByOwner
} from "@/store/custom"
import { approveUser, updateUser, adminUpdates } from "@/graphql/mutations";
import {
    path
} from "ramda"
const state = {
    user: "",
    userDetails: "",
    state: "signIn",
    pending: false,
    users: [],
    loading: false,
    userSearch: ""
}

const getters = {
    userDistributorName(state) {
        if (!path(["state", "user", "attributes", "custom:distributor"], state)) {
            return "NA"
        } else return state.user.attributes["custom:distributor"]
    },
    userDistributorId(state) {
        if (!path(["state", "user", "attributes", "custom:distributor"], state)) {
            return "NA"
        } else return state.user.attributes["custom:distributor"]
    },
    userStoreName(state) {
        if (!path(["state", "user", "attributes", "custom:store"], state)) {
            return "NA"
        } else return state.user.attributes["custom:store"]
    },
    userId(state) {
        return state.user.username
    },
    // userDistributorId(state) {
    //     return state.user.attributes["custom:distributor"]
    // },
    isAuthenticated(state) {
        return !!state.user
    },
    isSuper(state) {
        if (!state.user) return
        if (state.user.attributes["custom:auth"] === "super") return true
        else return false
    },
    isDistributor(state) {
        if (!state.user) return
        if (state.user.attributes["custom:auth"] === "distributor") return true
        else return false
    },
    isStore(state) {
        if (!state.user) return
        if (state.user.attributes["custom:auth"] === "store") return true
        else return false
    },
    isAdmin(state) {
        if (!state.user) return
        if (state.user.attributes["custom:auth"] === "admin") return true
        else return false
    },
    isTester(state) {
        if (!state.user) return
        if (state.user.attributes["email"] === "testing@bwr-innovations.com") return true
        else return false
    },
    isUser(state) {
        if (!state.user) return
        if (state.user.attributes["custom:auth"] === "user") return true
        else return false
    },
    isLocation(state) {
        if (!state.user) return
        if (state.user.attributes["custom:auth"] === "location") return true
        else return false
    },
    isCustomer(state) {
        if (!state.user) return
        if (state.user.attributes["custom:auth"] === "customer") return true
        else return false
    },
    isMDX(state) {
        if (!state.user) return
        if (state.user.attributes["custom:auth"] === "mdx") return true
        else return false
    },
    isKDX(state) {
        if (!state.user) return
        if (state.user.attributes["custom:auth"] === "kdx") return true
        else return false
    },
    isTDX(state) {
        if (!state.user) return
        if (state.user.attributes["custom:auth"] === "tdx") return true
        else return false
    },
    isFuelCell(state) {
        if (!state.user) return
        if (state.user.attributes["custom:auth"] === "fuelcell") return true
        else return false
    },
    isPending(state) {
        return state.pending
    },
    userEmails(state) {
        if (!state.users.length) return
        return state.users.map(item => item.email).sort((a,b) => a < b ? -1 : 1)
    }
}

const actions = {
    async login({
        commit,
        dispatch
    }, user) {
        commit("setUser", user)
        return
    },
    async logout({
        commit
    }) {
        await Auth.signOut({
            global: true
        })
        commit("removeUser")
        router.push('/login')
    },
    async getDistributorUsers({
        commit,
        dispatch
    }, dist) {
        const {
            data: {
                listUsers: {
                    items
                }
            }
        } = await API.graphql(graphqlOperation(listUsersByDistributor, {
            filter: {
                auth: {
                    ne: "super"
                }
            },
            limit: 1500
        }))
        let allUsers = items.filter(item => {
            if (item.auth === "distributor") {
                return item.distributor.name === dist
            } else if (item.auth === "store") {
                return item.store.distributor.name === dist
            } else if (item.auth === "user") {
                return item.location.store.name === dist
            }
        })
        commit("setUsers", allUsers)
        // commit("setUsers", await dispatch("filterDistributorUsers", items[0]))
    },
    // async getOwnerUsers({
    //     commit,
    //     dispatch
    // }, owner) {
    //     const {
    //         data: {
    //             listOwners: {
    //                 items
    //             }
    //         }
    //     } = await API.graphql(graphqlOperation(listUsersByOwner, {
    //         filter: {
    //             name: {
    //                 eq: owner
    //             }
    //         }
    //     }))
    //     commit("setUsers", await dispatch("filterUsers", items[0].users.items))
    // },
    async getAllUsers({
        commit,
        dispatch
    }) {
        const {
            data: {
                listUsers: {
                    items
                }
            }
        } = await API.graphql(graphqlOperation(listAllUsers, {
            limit: 1000
        }))
        commit("setUsers", await dispatch("filterSuperUsers", items))
    },
    async getUserDetails({
        commit,
        dispatch
    }, id) {
        const {
            data
        } = await API.graphql({
            query: getUser,
            variables: {
                id
            }
        })
        commit("setUserDetails", data.getUser)
        return data.getUser
    },
    async getLocationCode({}, code) {
        const {
            data
        } = await API.graphql({
            query: locationByCode,
            variables: {
                code
            }
        })
        if (data.locationByCode.items.length) return data.locationByCode.items[0].id
        else return false
    },
    async getUserCode({}, code) {
        const {
            data
        } = await API.graphql({
            query: userByCode,
            variables: {
                code
            }
        })
        if (data.userByCode.items.length) return data.userByCode.items[0].id
        else return false
    },
    async getAllUserDevices({
        commit,
        dispatch
    }, id) {
        const {
            data
        } = await API.graphql({
            query: getUserDevices,
            variables: {
                id
            }
        })
        commit("setUserDetails", data.getUser)
        return data.getUser
    },
    async filterDistributorUsers({}, users) {
        let all = []
        // console.log(users)
        users.owners.items.forEach(item => {
            all.push(item)
        })
        users.stores.items.forEach(item => {
            item.admins.items.forEach(item => {
                all.push(item)
            })
        })
        // console.log(all)
    },
    async filterSuperUsers({}, users) {
        return users.map(item => {
            return {
                id: item.id,
                first: item.first,
                last: item.last,
                email: item.email,
                auth: item.auth,
                admin: item.store ? item.store.name : item.location ? item.location.name : null,
                degreePref: item.degreePref ? item.degreePref : "Fahrenheit",
                status: item.status,
                distributor: item.distributor,
                store: item.store,
                location: item.location,
                approved: item.approved,
                sensors: item.sensors.items
            }
        })
        .sort((a,b) => a.email > b.email ? 1 : a.email < b.email ? -1 : 0)
    },
    async checkUser({
        commit,
        state,
        dispatch
    }) {
        try {
            let user = await Auth.currentAuthenticatedUser()
            await dispatch("getUserDetails", user.username)
            commit("setUser", user)
            return true
        } catch (error) {
            return false
        }
    },
    async checkUserPermission({
        commit,
        state,
        dispatch
    }) {
        try {
            let user = await Auth.currentAuthenticatedUser()
            let { auth } = await dispatch("getUserDetails", user.username)
            commit("setUser", user)
            return auth
        } catch (error) {
            return false
        }
    },
    async pendingUser({
        commit
    }) {
        commit("setPending", true)
    },
    async approveUserRegistration({
        commit
    }, user) {
        const {
            data
        } = await API.graphql(graphqlOperation(approveUser, {
            userId: user.id,
            userEmail: user.email
        }))
        if (data.approveUser) {
            commit("updateApproveUser", user.id)
        } else {
            // console.log("User could not be verified")
        }
    },
    async updateUserLocation({
        commit
    }, { id, location }) {
        const {
            data
        } = await API.graphql({
            query: updateUser,
            variables: {
                input: {
                    id,
                    userLocationId: location
                }
            }
        })
        if (data.updateUser.id && !location) {
            commit("setUserLocation", data.updateUser.id)
            return true
        }
        else if (data.updateUser.id) return true
        else return false
    },
    async updateUserPropertySimple({
        commit,
        dispatch
    }, user) {
        const {
            data
        } = await API.graphql({
            query: updateUser,
            variables: {
                input: {
                    id: user.id,
                    [user.prop]: user.value
                }
            }
        })
        if (user.auth || true) {
            let userSensors
            if (user.auth === "store" || true) {
                // userSensors = await dispatch("getAdminStoreSensors", user.id)
                userSensors = await dispatch("getAdminStoreSensors", "694a4b9d-fb4d-4f40-8012-c8def98698d3")
                console.log(userSensors)
            }
            else userSensors = user.sensors
        }
        // await dispatch("adminUpdateUserSensors", userSensors)
        await dispatch("checkUser")
        // commit("setUser", data.updateUser)
    },
    async getAdminStoreSensors({
        state,
        commit,
        dispatch
    }, userId) {
        const {
            data: {
                getUser: {
                    store: {
                        locations: {
                            items
                        }
                    }
                }
            }
        } = await API.graphql({
            query: getStoreAdminSensors,
            variables: {
                id: userId
            }
        })
        return [].concat(...items.map(item => item.sensors.items))
    },
    async adminUpdateUserSensors({
        commit
    }, sensors) {
        const {
            data
        } = await API.graphql({
            query: adminUpdates,
            variables: {
                func: "updateUserSensor",
                val: JSON.stringify(sensors)
            }
        })
        if (adminUpdates) return true
        else return false
    },
    async adminUpdateUserAuth({
        commit
    }, user) {
        const {
            data
        } = await API.graphql({
            query: adminUpdates,
            variables: {
                func: "updateAuth",
                val: JSON.stringify(user)
            }
        })
        if (adminUpdates) return true
        else return false
    },
    async adminUpdateUserEmail({
        commit
    }, user) {
        const {
            data
        } = await API.graphql({
            query: adminUpdates,
            variables: {
                func: "updateUserEmail",
                val: JSON.stringify(user)
            }
        })
        if (adminUpdates) return true
        else return false
    },
    async disableUserRegistration({
        commit
    }, id) {
        const {
            data
        } = await API.graphql(graphqlOperation(disableUser, {
            userId: id
        }))
        if (data.approveUser) {
            commit("updateApproveUser", user.id)
        } else {
            // console.log("User could not be verified")
        }
    }
}

const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setUserLocation(state, userId) {
        let index = state.users.findIndex(item => item.id === userId)
        state.users[index].location = null
    },
    setUserDetails(state, details) {
        state.userDetails = details
    },
    updateApproveUser(state, userId) {
        let index = state.users.findIndex(item => item.id === userId)
        state.users[index].approved = true
    },
    setState(state, userState) {
        state.state = userState
    },
    removeUser(state) {
        state.user = ""
    },
    setPending(state) {
        state.pending = true
    },
    setUsers(state, users) {
        state.users = users
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setSearch(state, val) {
        state.userSearch = val
    },
    removeSearch(state) {
        state.userSearch = ""
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}